import * as React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import iOSButton from "../images/download-appstore.png";
import AndroidButton from "../images/download-googleplay.png";

const APPSTORE_URL = "https://apps.apple.com/app/littlelike-me-life-sim-game/id1510641252";
const GOOGLEPLAY_URL = "https://play.google.com/store/apps/details?id=com.SixtyFive02.littlelikeme";

const DownloadLinks = () => {
  return (
    <>
      <div class="flex flex-wrap items-center justify-center md:justify-start gap-5 mx-auto">
        <OutboundLink href={APPSTORE_URL}>
          <img className="max-h-14" src={iOSButton} />
        </OutboundLink>
        <OutboundLink href={GOOGLEPLAY_URL}>
          <img className="max-h-14" src={AndroidButton} />
        </OutboundLink>
      </div>
    </>
  );
};

export default DownloadLinks;
