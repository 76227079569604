import * as React from 'react';
import {OutboundLink} from 'gatsby-plugin-google-analytics';

const ButtonRed = ({buttonText, buttonLink}) => {
  return (
    <OutboundLink href={buttonLink}>
      <button className="inline-block mx-1 my-1 px-8 py-3 rounded-md text-white bg-red-6502 hover:bg-blue-800 uppercase text-xs font-bold tracking-wider">
        {buttonText}
      </button>
    </OutboundLink>
  );
};

export default ButtonRed;
