import * as React from "react";

const FeatureItemRight = ({ featureImage, title, children }) => {
  console.log(featureImage);
  return (
    <div className="container my-20">
      <p className="text-2xl font-black">{title}</p>
      <div className="flex flex-wrap md:flex-nowrap justify-center content-center mt-4">
        <div className="my-auto max-w-sm md:max-w-xs lg:max-w-sm md:mr-10 text-left py-10 md:py-0">
          <p className="text-sm md:text-base">{children}</p>
        </div>
        <div>{featureImage()}</div>
      </div>
    </div>
  );
};

export default FeatureItemRight;
