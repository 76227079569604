import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import FeatureItem from "./featureitem";
import FeatureItemRight from "./featureitemright";

const AllFeatures = () => {
  return (
    <div className="container mx-auto">
      <FeatureItem
        featureImage={() => (
          <StaticImage
            src="../images/features/dressup-loyalty.png"
            width="512"
          />
        )}
        title="Customize Your Character"
      >
        Customize your character with different hair styles, clothing, shoes,
        accessories and more. Don’t get caught wearing the default outfit! As
        you earn more Coins, you can upgrade your style and unlock Loyalty
        Cards. Loyalty Cards give you Loyalty Points that can be redeemed for
        free Diamonds!{" "}
      </FeatureItem>
      <FeatureItemRight
        featureImage={() => (
          <StaticImage
            src="../images/features/guesthouse-map.png"
            width="512"
          />
        )}
        title="Explore Apartments in the City"
      >
        After a short stay at Starla’s guest house, you’ll explore your way
        through apartments on the City Map, decorating each one along the way.
        Each one has its own unique challenges for decorating, so do your best
        to earn enough Keys to Move Out to the next apartment.
        <p className="mt-4">
          Every apartment also has a new Public Space, so be sure to Go Out and
          meet the characters there and see what you can do.
        </p>
      </FeatureItemRight>
      <FeatureItem
        featureImage={() => (
          <StaticImage src="../images/features/mood.png" width="512" />
        )}
        title="Take Care of Your Mood"
      >
        <p>
          Take care of your Mood! A good mood is important for building Skills,
          socializing with other characters, and doing Side Jobs. Your mood is
          made of different Needs, including Deco, Joy, Self-Care, Rest, and
          Health. Buy deco objects to help satisfy each of these needs to stay
          in a Good mood, then try to buy better deco to get an Awesome mood.
        </p>
        <p className="mt-4">
          Your character is “autonomous”, meaning that she’ll try to use the
          right objects on her own, but it’s up to you to help her get the best
          mood possible.
        </p>
      </FeatureItem>
      <FeatureItemRight
        featureImage={() => (
          <StaticImage src="../images/features/deco.png" width="512" />
        )}
        title="Decorate"
      >
        Decorate with no limits! You can move and rotate deco objects any way
        that you want - you aren’t limited to a simple grid.
        <p className="mt-4">
          If two deco objects overlap, they’ll automatically move back to the
          best place, or stash themselves in your list of Owned items to be used
          later.
        </p>
        <p className="mt-4">
          Start by buying basic 1 and 2-star Deco to take care of your Mood,
          then buy better 4 and 5-star Deco when you have more Coins. There are
          different Deco categories for each of the Needs for your Mood.
        </p>
      </FeatureItemRight>
      <FeatureItem
        featureImage={() => (
          <StaticImage src="../images/features/sidejobs.png" width="512" />
        )}
        title="Earn Coins from Side Jobs"
      >
        Need Coins? Complete Side Jobs!
        <p className="mt-4">
          Every apartment has a list of different Side Jobs, and they get more
          challenging as you progress through the City Map. Easy Side Jobs just
          need you to have a Good Mood and some Tickets or Social Points. Later
          Side Jobs will need a Great Mood, Skills, Personalities, and more.
        </p>
        <p className="mt-4">
          Some Side Jobs have more than one level, and others can be done as
          many times as you want. And many Side Jobs can only be completed after
          a certain amount of time - you can use Diamonds to speed this time up.{" "}
        </p>
        <p className="mt-4">
          Harder Side Jobs pay more Coins, and the last Side Job at every
          apartment will give you Keys so you can Move Out.
        </p>
        <p className="mt-4">
          Side Jobs can also hurt your Mood. Easy Side Jobs might put you in a
          Bored Mood, but hard Side Jobs can put you in a very Bad Mood. Keep
          buying better deco for your apartment to help with your Mood.
        </p>{" "}
      </FeatureItem>
      <FeatureItemRight
        featureImage={() => (
          <StaticImage src="../images/features/social.png" width="512" />
        )}
        title="Socialize"
      >
        You can Socialize with every character you meet. The more you socialize,
        the more you’ll earn Social Points. You need Social Points to complete
        most Side Jobs, but you can also use Social Points to unlock Gift Boxes.
        If you want to earn even more Social Points, you can use Coins upgrade
        the max amount. You can also use Coins to buy upgrades that let you earn
        Social Points faster.
        <p className="mt-4">
          Did you know that all of the female characters in the game are
          actually created by other real players? Who knows who you might meet!
        </p>
      </FeatureItemRight>
      <FeatureItem
        featureImage={() => (
          <StaticImage src="../images/features/relationships.png" width="512" />
        )}
        title="Build Relationships"
      >
        Socializing is also a great way to build Relationships with other
        characters. You can start as an Acquaintance and work your way all the
        way to Best Friend. Look for the special effects around your character
        when there’s a good relationship. When you socialize with characters
        that you have a good relationship with, you’ll earn Social Points
        faster. But beware, relationships decay over time, so be sure to keep
        socializing.
        <p className="mt-4">
          No time to socialize? Send Gifts to your friends to boost and maintain
          relationships, even if they aren’t online! They might even send you
          some gifts back.
        </p>
      </FeatureItem>
      <FeatureItemRight
        featureImage={() => (
          <StaticImage src="../images/features/emotogram.png" width="512" />
        )}
        title="Use Emotograms"
      >
        When you socialize, you can also use Emotograms. Emotograms are ways to
        express yourself and your feelings, with unique animations and popup
        phrases. They’re used automatically when they are unlocked, upgraded,
        and equipped, and will boost the relationship of the character you’re
        socializing with.
        <p className="mt-4">
          Emotograms can be found in Gift Boxes, which you can unlock for
          yourself with Social Points or Diamonds, or you can get as gifts from
          friends. They also have rarity. The rarer the Emotogram, the more it
          boosts relationships.
        </p>
        <p className="mt-4">
          Since good relationships earn Social Points faster, you should always
          have Emotograms equipped and ready to use.
        </p>
      </FeatureItemRight>
      <FeatureItem
        featureImage={() => (
          <StaticImage src="../images/features/personality.png" width="512" />
        )}
        title="Express Your Personality"
      >
        What kind of Personality does your character have? You can use
        Personality Points to upgrade different types of Personality Attributes,
        which will in turn determine how your character behaves when using
        objects and when socializing. There is no “best” personality, so upgrade
        the attributes you like the most.
        <p className="mt-4">
          Every time you use Personality Points to upgrade your personality, you
          will increase your Personality Level. Some Side Jobs will require a
          high Personality Level.
        </p>
        <p className="mt-4">
          Need more Personality Points? Find and upgrade Emotograms - you’ll
          earn more Personality Points every time you do. You can even reset
          your Personality with Diamonds.{" "}
        </p>
      </FeatureItem>
      <FeatureItemRight
        featureImage={() => (
          <StaticImage src="../images/features/skills.png" width="512" />
        )}
        title="Earn Skills"
      >
        Many Side Jobs require Skills. And some Public Spaces have fun objects
        that you can only use if you have the right Skills.
        <p className="mt-4">
          Buy Skill Deco objects, and put them anywhere in your apartment. But
          don’t forget - you need to be in a Good Mood to use Skill objects and
          earn Skills. If you max out a Skill, you can buy an upgrade to
          increase the max amount.
        </p>
        <p className="mt-4">
          And every time you earn a Skill, some Diamonds are deposited in the
          Piggy Bank!
        </p>
      </FeatureItemRight>
      <FeatureItem
        featureImage={() => (
          <StaticImage src="../images/features/pet.png" width="512" />
        )}
        title="Enjoy Life in littlelike.me"
      >
        Remember to pet the dog!
      </FeatureItem>
    </div>
  );
};

export default AllFeatures;
